.main-footer {
	transition: all .5s;
}

body.sidebar-mini .main-footer {
	padding-left: 90px;
}

body.layout-2 .main-footer {
	margin-left: 230px;
	width: calc(100% - 230px);
	padding-left: 0;
	padding-right: 0;
}

body.layout-2 .main-footer {
	flex-shrink: 0;
	flex-grow: 0;
}

body.layout-3 .main-footer {
	padding-left: 0;
	padding-right: 0;
}

.main-footer {
	padding: 20px 30px 20px 280px;
	margin-top: 40px;
	color: #98a6ad;
	border-top: 1px solid #e3eaef;
	display: inline-block;
	width: 100%;
}

.main-footer .footer-left {
	float: left;
}

.main-footer .footer-right {
	float: right;
}

.bullet, .slash {
	display: inline;
	margin: 0 4px;
}

.bullet:after {
	content: '\2022';
}

@media (max-width: 1024px) {
    .main-footer {
		padding-left: 30px;
    }
}