.card__info:focus, .card__info:hover{
    opacity: 1;
    outline: none;
}

.card__info1:focus, .card__info1:hover{
    opacity: 1;
    outline: none;
    color: green;
}

.card__info2:focus, .card__info2:hover{
    opacity: 1;
    outline: none;
    color: #3AD022;
}

.card__info3:focus, .card__info3:hover{
    opacity: 1;
    outline: none;
    color: #FFC312;
}

.card__info4:focus, .card__info4:hover{
    opacity: 1;
    outline: none;
    color: red;
}

.card__info6:focus, .card__info6:hover{
    opacity: 1;
    outline: none;
    color: blue;
}

[data-msg] {
    position: relative;
    font-size: 1rem;
}


[data-msg]:focus::before, [data-msg]:hover::before{
    content: attr(data-msg);

    transition: ease-out .3s;
    transition: all ease-in-out .6s;
    transition: all ease-in-out .6s;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 5px 8px 30px rgba(0,0,0,0.5);

    position: absolute;
    width: 100%;
    min-width: 150px;
    padding: 1rem;
    background-color: white;

    left: 40%;
    top: -2rem;
    transform: translate(-50%, -100%);
}

[data-msg]:focus::after, [data-msg]:hover::after{
    content: '';
    position: absolute;
    top: -32px;
    left: 40%;
    transform: translateX(-50%);
    border-top: 15px solid white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}

.btnRead {
    background-color: transparent;
    border: none;
    font-weight: 500;
    color: blue;
    cursor: pointer;
    border-color: transparent;
}

.flagIcon {
    background-color: transparent;
    border-color: transparent;
    left: -20px;
}

.newStyle {
    width: 100%;
    height: 92%;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px; 
    border-top-right-radius: 24px;
}

.cardHeader {
    background-color: transparent;
    border-color: transparent;
    bottom: -1px;
}

.topRadius{
    border-top-left-radius: 20px; 
    border-top-right-radius: 20px;
}

.stringStyle {
    font-size: 14px;
    padding-top: 8em; 
}

.graphValue{
    background-color: transparent; 
    border-color: transparent;
    bottom: 70px;
}

.value {
    font-size: 20px; 
    color: transparent;
}

.infoPopUp{
    font-size: 16px;
    color: #C8C8C8;
}
