.main-sidebar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #fff;
  z-index: 1;
  left: 0;
  transition: all 0.5s;
  overflow: scroll;
}

body.sidebar-gone .main-sidebar {
  left: -250px;
}

body.sidebar-mini .main-sidebar {
  width: 65px;
  overflow: initial !important;
  position: absolute;
  box-shadow: none;
}

body.sidebar-mini .main-sidebar:after {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  content: ' ';
  position: fixed;
  background-color: #fff;
  width: 65px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  -webkit-animation-name: mini-sidebar;
  animation-name: mini-sidebar;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

body.sidebar-mini .main-sidebar .sidebar-brand {
  display: none;
}

body.sidebar-mini .main-sidebar .sidebar-brand-sm {
  display: block;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li {
  padding: 10px;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li.menu-header {
  padding: 0;
  font-size: 0;
  height: 2px;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li > a {
  border-radius: 3px;
  height: 45px;
  padding: 0;
  justify-content: center;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li > a .ion,
body.sidebar-mini .main-sidebar .sidebar-menu > li > a .fas,
body.sidebar-mini .main-sidebar .sidebar-menu > li > a .far,
body.sidebar-mini .main-sidebar .sidebar-menu > li > a .fab,
body.sidebar-mini .main-sidebar .sidebar-menu > li > a .fal {
  margin: 0;
  font-size: 20px;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li > a span {
  display: none;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li > a .badge {
  padding: 5px;
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 10px;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li > a.has-dropdown:after {
  content: initial;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li.active > a {
  box-shadow: 0 4px 8px #acb5f6;
  background-color: #054aaf;
  color: #fff;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu {
  position: absolute;
  background-color: #fff;
  left: 65px;
  top: 10px;
  width: 200px;
  display: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
}

body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li > a:focus,
body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li.active > a,
body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li.active > a:hover {
  color: #fff;
  background-color: #054aaf !important;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li a {
  height: 40px;
  padding: 0 20px;
  background-color: #fff;
}

body.sidebar-mini .main-sidebar .sidebar-menu > li ul.dropdown-menu li a.has-dropdown:after {
  content: '\F054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  font-size: 12px;
  transition: all 0.5s;
}

body.sidebar-mini .main-sidebar .sidebar-menu li:hover > ul.dropdown-menu {
  display: block !important;
}

body.sidebar-mini .main-sidebar .sidebar-menu li:hover > ul.dropdown-menu li:hover > a {
  background-color: #fcfcfd;
}

body.sidebar-mini .main-sidebar .sidebar-menu li:hover > ul.dropdown-menu li .dropdown-menu {
  left: 200px;
  padding: 0;
}

body.layout-2 .main-sidebar,
body.layout-2 .main-content {
  flex-shrink: 0;
  flex-grow: 0;
}

body.layout-2 .main-sidebar {
  background-color: transparent;
  box-shadow: none;
  position: static;
  margin-top: 100px;
  width: 200px;
}

body.layout-2 .main-sidebar .sidebar-menu li.menu-header {
  padding: 0;
}

body.layout-2 .main-sidebar .sidebar-menu li a {
  padding: 0;
}

body.layout-2 .main-sidebar .sidebar-menu li a i {
  width: 10px;
}

body.layout-2 .main-sidebar .sidebar-menu li a.has-dropdown:after {
  right: 0;
}

body.layout-2 .main-sidebar .sidebar-menu li a:hover {
  color: #054aaf;
  background-color: transparent;
}

body.layout-2 .main-sidebar .sidebar-menu li ul.dropdown-menu li a {
  padding-left: 34px;
}

body.layout-2 .main-sidebar .sidebar-menu li.active a {
  background-color: transparent;
}

body.layout-2 .main-sidebar .sidebar-menu li.active ul.dropdown-menu {
  background-color: transparent;
}

body.layout-2 .main-sidebar .sidebar-menu li.active ul.dropdown-menu a {
  background-color: transparent;
}

.main-sidebar .sidebar-brand {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
}

.main-sidebar .sidebar-brand.sidebar-brand-sm {
  display: none;
}

.main-sidebar .sidebar-brand a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 700;
  color: #000;
}

.main-sidebar .sidebar-user {
  display: inline-block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.main-sidebar .sidebar-user .sidebar-user-picture {
  float: left;
  margin-right: 10px;
}

.main-sidebar .sidebar-user .sidebar-user-picture img {
  width: 50px;
  border-radius: 50%;
}

.main-sidebar .sidebar-menu {
  padding: 0;
  margin: 0;
}

.main-sidebar .sidebar-menu li {
  display: block;
}

.main-sidebar .sidebar-menu li.menu-header {
  padding: 3px 15px;
  color: #a1a8ae;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 600;
}

.main-sidebar .sidebar-menu li.menu-header:not(:first-child) {
  margin-top: 10px;
}

.main-sidebar .sidebar-menu li a {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  width: 100%;
  letter-spacing: 0.3px;
  color: #78828a;
  text-decoration: none;
}

.main-sidebar .sidebar-menu li a .badge {
  float: right;
  padding: 5px 10px;
  margin-top: 2px;
}

.main-sidebar .sidebar-menu li a i {
  width: 28px;
  margin-right: 20px;
  text-align: center;
}

.main-sidebar .sidebar-menu li a span {
  margin-top: 3px;
  width: 100%;
}

.main-sidebar .sidebar-menu li a:hover {
  background-color: #f8fafb;
}

.main-sidebar .sidebar-menu li.active a {
  color: #054aaf;
  font-weight: 600;
  background-color: #f8fafb;
}

.main-sidebar .sidebar-menu li.active a.has-dropdown:after {
  transform: translate(0, -50%) rotate(90deg);
}

.main-sidebar .sidebar-menu li.active ul.dropdown-menu {
  background-color: #f8fafb;
}

.main-sidebar .sidebar-menu li a.has-dropdown:after {
  content: '\F054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  font-size: 12px;
  transition: all 0.5s;
}

.main-sidebar .sidebar-menu li.active > ul.dropdown-menu li a:hover {
  background-color: #f8fafb;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu {
  padding: 0;
  margin: 0;
  display: none;
  position: static;
  float: none;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu li a {
  color: #868e96;
  height: 35px;
  padding-left: 65px;
  font-weight: 400;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu li a:hover {
  color: #054aaf;
  background-color: inherit;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu li.active > a {
  color: #054aaf;
  font-weight: 600;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu li a i {
  margin-top: 1px;
  text-align: center;
}

.main-sidebar .sidebar-menu li ul.dropdown-menu li ul.dropdown-menu {
  padding-left: 10px;
}

body:not(.sidebar-mini) .sidebar-style-1 .sidebar-menu li.active a {
  background-color: #054aaf;
  color: #fff;
}

body:not(.sidebar-mini) .sidebar-style-1 .sidebar-menu li.active ul.dropdown-menu li a {
  color: #e8ebfd;
}

body:not(.sidebar-mini) .sidebar-style-1 .sidebar-menu li.active ul.dropdown-menu li a:hover {
  background-color: #054aaf;
  color: #fff;
}

body:not(.sidebar-mini) .sidebar-style-1 .sidebar-menu li.active ul.dropdown-menu li.active a {
  color: #fff;
}

body:not(.sidebar-mini) .sidebar-style-2 .sidebar-menu > li.active > a {
  padding-left: 16px;
  background-color: transparent;
  position: relative;
}

body:not(.sidebar-mini) .sidebar-style-2 .sidebar-menu > li.active > a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  width: 4px;
  background-color: #054aaf;
}

body:not(.sidebar-mini) .sidebar-style-2 .sidebar-menu li.active ul.dropdown-menu li a {
  padding-left: 61px;
  background-color: #fff;
}

.nav-tabs .nav-item .nav-link {
  color: #054aaf;
}

.nav-tabs .nav-item .nav-link.active {
  color: #000;
}

.nav-pills .nav-item .nav-link {
  color: #054aaf;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.nav-pills .nav-item .nav-link:hover {
  background-color: #f6f7fe;
}

.nav-pills .nav-item .nav-link.active {
  box-shadow: 0 2px 6px #acb5f6;
  color: #fff;
  background-color: #054aaf;
}

.nav-pills .nav-item .nav-link .badge {
  padding: 5px 8px;
  margin-left: 5px;
}

.nav .nav-item .nav-link .ion,
.nav .nav-item .nav-link .fas,
.nav .nav-item .nav-link .far,
.nav .nav-item .nav-link .fab,
.nav .nav-item .nav-link .fal {
  margin-right: 3px;
  font-size: 12px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item.active > .nav-link {
  color: #054aaf;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item.active > .nav-link:before {
  left: 35px;
  right: 0;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item:first-child .nav-link {
  margin-left: 0;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item:last-child .nav-link {
  margin-right: 0;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link {
  color: #868e96;
  font-size: 13px;
  letter-spacing: 0.3px;
  height: 70px;
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link.has-dropdown {
  margin-right: 35px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link.has-dropdown:after {
  content: '\F054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  font-size: 12px;
  transition: all 0.5s;
  right: -20px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link:before {
  content: ' ';
  position: absolute;
  left: initial;
  right: initial;
  bottom: 0;
  height: 2px;
  background-color: #054aaf;
  transition: all 0.5s;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link span {
  line-height: 74px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link i {
  width: 30px;
  font-size: 16px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link:hover {
  color: #191d21 !important;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu {
  padding: 0;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item .nav-link {
  color: #6c757d;
  font-weight: 600;
  letter-spacing: 0.3px;
  padding: 7px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item .nav-link.has-dropdown:after {
  content: '\F054';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  font-size: 12px;
  transition: all 0.5s;
  right: 15px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item:hover > .nav-link {
  background-color: #fcfcfd;
  color: #191d21;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item:hover > .dropdown-menu {
  display: block !important;
  top: -5px;
  left: 200px;
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item.active > .nav-link,
body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item .dropdown-menu .nav-item .nav-link:focus {
  background-color: #054aaf;
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #054aaf;
}

.navbar .active .nav-link {
  color: #fff;
  font-weight: 700;
}

.navbar .nav-link {
  color: #f2f2f2;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100%;
}

.navbar .nav-link.nav-link-lg div {
  margin-top: 3px;
}

.navbar .nav-link.nav-link-lg i {
  margin-left: 0 !important;
  font-size: 18px;
  line-height: 32px;
}

.navbar .nav-link.nav-link-user {
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
}

.navbar .nav-link.nav-link-user img {
  width: 30px;
}

.navbar .nav-link.nav-link-img {
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 50%;
  overflow: hidden;
}

.navbar .nav-link.nav-link-img .flag-icon {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  line-height: 18px;
  height: 22px;
  width: 22px;
  background-size: cover;
}

.navbar .nav-link:hover {
  color: #fff;
}

.navbar .nav-link.disabled {
  color: #fff;
  opacity: 0.6;
}

body.sidebar-mini .hide-sidebar-mini {
  display: none !important;
}

.highlight-text {
  color: black; 
  font-weight: bold;
}

@media (max-width: 1024px) {
  .main-sidebar {
    position: fixed !important;
    margin-top: 0 !important;
    z-index: 891;
  }
  .nav-collapse .navbar-nav .nav-item:first-child {
    border-radius: 3px 3px 0 0;
  }

  .nav-collapse .navbar-nav .nav-item:last-child {
    border-radius: 0 0 3px 3px;
  }

  .nav-collapse .navbar-nav .nav-item .nav-link {
    background-color: #fff;
    color: #6c757d;
  }

  .nav-collapse .navbar-nav .nav-item .nav-link:hover {
    background-color: #fcfcfd;
    color: #054aaf;
  }

  .nav-collapse .navbar-nav .nav-item:focus > a,
  .nav-collapse .navbar-nav .nav-item.active > a {
    background-color: #054aaf;
    color: #fff;
  }
}

@media (max-width: 575.98px) {
  .navbar .nav-link.nav-link-lg div {
    display: none;
  }
}

.sidebar-menu > li > a > i {
  color: #054aaf;
}
