.init-0 {
    height: 0px !important;
    bottom: 0 !important;
}
.progress-bar {
    width: 100%;
    margin-bottom: 5px;
    background-color: #007bff00;
    bottom: 5px;
}
.bar {
    height: 350px;
    width: 45px;
    position: relative;
    margin: 0 60px 0 50px;
}
.bar.fill-blue {
    background: linear-gradient(to bottom, green 0%, green 33%, yellow 33%, yellow 66%, red 66%, red 100%);
    /*background-color: indigo;*/
    border-radius: 8px;
}

.maximum {
    position: absolute;
    border-bottom: 2px solid #006400;
    right: 6px;
    text-align: right;
    transition: 0.5s;
    top: 0;
    width: 65px;
    transform: skew(45deg, 0);
    border-bottom: none;
    border-top: 2px solid #006400;
    border-right: 3px solid #006400;
    height: 14px;
    width: 27px;
    top: -15px;
    right: 29px;
}

.maximum:before {
    content: '';
    background-color: #006400;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    position: absolute;
    bottom: -4px;
    right: -4px;
    transform: skew(-45deg, 0);
}

.maximum:after {
    content: '';
    background-color: #006400;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    bottom: -6px;
    position: absolute;    
    left: -9px;
    bottom: 8px;
    transform: skew(-45deg, 0);
}

.high-critical-number {
    position: absolute;
    bottom: -5px;
    color: #006400;
    right: 30px;
    transform: skew(-45deg, 0);
}

.progress {
    position: absolute;
    width: 65px;
    background-color: #e9ecef00 !important;
    border-bottom: 2px solid #2d2c2c;
    left: 6px;
    text-align: right;
    transition: 0.5s;
    /* bottom: ${currentScore < criticalNumber ? Math.round((currentScore * heightOfGraph) / criticalNumber) - 1 : Math.round((criticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.progress:before {
    content: '';
    background-color: #2d2c2c;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -4px;
}

.progress:after {
    content: '';
    background-color: #2d2c2c;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    bottom: -4px;
    position: absolute;
}

.current-score {
    position: absolute;
    font-size: 1.2rem;
    left: 68px;
    bottom: -12px;
    color: #000;
}

.minimum {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid red;
    right: 6px;
    transition: 0.5s;
    bottom: 5px;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px;  -6 */
}

.minimum:before {
    content: '';
    background-color:red;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;

}

.low-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: red;
}

.rock-section-item {
    position: absolute;
    right: 100px;
    bottom: -19px;
    color: black;
    font-size: x-large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.current{
    position: absolute;
    width: 65px;
    border-bottom: 2px solid black;
    left: 6px;
    transition: 0.5s;
}

.current:before{
    content: '';
    background-color: black;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -4px;
}

.current:after{
    content: '';
    background-color: black;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    bottom: -4px;
    position: absolute;
}

.current-score2{
    position: absolute;
    right: -70px;
    bottom: -31px;
    color: black;
}

.medium {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid #FFC312;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.medium:before {
    content: '';
    background-color:#FFC312;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}


.medium-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: #E7E427;
}

.bottom-high {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid green;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.bottom-high :before {
    content: '';
    background-color:green;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 34px;
    position: absolute;
    bottom: 12px;
}


.bottom-high-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: green;
}

.critical {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid #3AD022;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.critical:before {
    content: '';
    background-color:#3AD022;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}


.normal-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: #3AD022;
}

.rock-section{
    height: 50px;
    background-color: blue;
    margin-bottom: -100px;
}