.title-r {
    font-family: 'Montserrat Regular';
}

.title-m {
    font-family: 'Montserrat Medium';
}

.title-b {
    font-family: 'Montserrat Bold';
}

.btn {
    background: none;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    transition: 0.2s;
    background-color: var(--primary);
    color: #fff;
    padding: 5px 10px;
}

.btn:active {
    transform: scale(0.95);
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.hidden {
    display: none;
}

.not-active {
    pointer-events: none;
    color: gray !important;
}
/* <~~ Global */

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}