.scoreboard {
    background: white;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    min-height: 350px;
    justify-content: space-between;
}

.scoreboard:first-child {
    margin-left: 0;
}

.scoreboard:last-child {
    margin-right: 0;
}

p, small {
    width: 100%;
}

.assigned, .department {
    color: #20a0d7;
    text-align: left;
    width: 100%;
    display: block;
}


.btn.edit {
    background: none;
    padding-bottom: 0;
    transition: 0.1s;
}

.name, .description {
    margin: 8px 0;
}

.name {
    font-size: 1.2rem;
    text-transform: capitalize;
}

.graph {
    margin-top: 50px;
    width: 100%;
}

.rock-item {
    position: relative;
    margin-bottom: -715px;
    margin-left: -40px;
}