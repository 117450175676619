.init-0 {
    height: 0px !important;
    bottom: 0 !important;
}
.progress-bar {
    width: 100%;
    margin-bottom: 5px;
    background-color: #007bff00
}
.bar {
    height: 350px;
    width: 45px;
    position: relative;
    margin: 0 60px 0 50px;
}
.bar.fill-blue {
    background: linear-gradient(to bottom, green 0%, green 33%, yellow 33%, yellow 66%, red 66%, red 100%);
    /*background-color: indigo;*/
    border-radius: 8px;
}


.maximum {
    position: absolute;
    border-bottom: 2px solid #006400;
    right: 6px;
    text-align: right;
    transition: 0.5s;
    top: 0;
    width: 65px;
    transform: skew(45deg, 0);
    border-bottom: none;
    border-top: 2px solid #006400;
    border-right: 3px solid #006400;
    height: 14px;
    width: 27px;
    top: -15px;
    right: 29px;
}

.maximum:before {
    content: '';
    background-color: #006400;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    position: absolute;
    bottom: -4px;
    right: -4px;
    transform: skew(-45deg, 0);
}


.high-critical-number {
    /*position: absolute;
    bottom: -5px;
    color: #006400;
    right: -40px;*/
    transform: skew(-45deg, 0);
    font-size: 14px;

    position: absolute;
    right: 50px;
    bottom: -25px;
    color: #3AD022;
}

.low-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: red;
}

.progress {
    position: absolute;
    width: 65px;
    background-color: #e9ecef00 !important;
    border-bottom: 2px solid #2d2c2c;
    left: 6px;
    text-align: right;
    transition: 0.5s;
    /* bottom: ${currentScore < criticalNumber ? Math.round((currentScore * heightOfGraph) / criticalNumber) - 1 : Math.round((criticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.progress:before {
    content: '';
    background-color: #2d2c2c;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -4px;
}

.progress:after {
    content: '';
    background-color: #2d2c2c;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    bottom: -4px;
    position: absolute;
}

.current-score {
    position: absolute;
    font-size: 1.2rem;
    left: 68px;
    bottom: -12px;
    color: #000;
}

.minimum {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid red;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.minimum:before {
    content: '';
    background-color:red;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}

.low-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: red;
}

.rock-section-item {
    position: absolute;
    right: 100px;
    bottom: -19px;
    color: black;
    font-size: x-large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.current{
    position: absolute;
    width: 65px;
    border-bottom: 2px solid black;
    left: 6px;
    transition: 0.5s;
}

.current:before{
    content: '';
    background-color: black;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -4px;
}

.current:after{
    content: '';
    background-color: black;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
    right: 0;
    bottom: -4px;
    position: absolute;
}

.current-score2{
    position: absolute;
    right: -50px;
    bottom: -31px;
    color: black;
}

.medium {
    position: absolute;
    width: 15px;
    border-bottom: 2px solid #FFC312;
    right: 6px;
    transition: 0.5s;
    bottom: 5px;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.medium:before {
    content: '';
    background-color:#FFC312;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}


.medium-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: #FFC312;
}

.critical {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid #3AD022;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.critical:before {
    content: '';
    background-color:#3AD022;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}

.high-critical {
    position: absolute;
    width: 65px;
    border-bottom: 2px solid green;
    right: 6px;
    transition: 0.5s;
    /* bottom: ${Math.round((lowCriticalNumber * heightOfGraph) / criticalNumber) - 1}px; */
}

.high-critical:before {
    content: '';
    background-color:green;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0;
    position: absolute;
    bottom: -6px;
}


.normal-critical-number {
    position: absolute;
    right: 50px;
    bottom: -31px;
    color: #3AD022;
}

.stage {
    transition: 0.5s;
}

.stage-4 {
    /* height: ${((criticalNumber - data.middleTopNumber) / criticalNumber) * 100}%; */
    background-color: #41A69A;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.stage-3 {
    /* height: ${((data.middleTopNumber - data.middleBottomNumber) / criticalNumber) * 100}%; */
    background-color: #6AD485;
}

.stage-2 {
    /* height: ${((data.middleBottomNumber - lowCriticalNumber) / criticalNumber) * 100}%; */
    background-color: #FFD17B;
}

.stage-1 {
    /* height: ${(lowCriticalNumber / criticalNumber) * 100}%; */
    background-color: #F28393;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

/* Info at the right side */
.info-right {
    /* height: ${heightOfGraph}px; */
    /* justify-content: ${justifyRightInfo}; */
}

/*assigned-photo*/
.assigned-photo img {
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    height: 50px;
}

.modify-score {
    color: #6777ef;
}

.modify-score small {
    white-space: nowrap;
}

.modify-score .btn {
    background-color: transparent;
    color: #20a0d7;
    transition: 0.1s;
    padding-bottom: 0;
}

.modify-score .btn:last-child {
    padding-top: 0;
}

.metric {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: right;
}

.metric-4 {
    color: #41A69A;
    position: relative;
}

.metric-4:after {
    content: '+';
    position: absolute;
}

.metric-3 {
    color: #6AD485;
}

.metric-2 {
    color: #FFC312;
}

.metric-1 {
    color: #F28393
}

.rock-section{
    height: 50px;
    background-color: blue;
    margin-bottom: -100px;
}